<template>
  <div class="pp">
    <div class="send-box">
      <!--快递配送-->
      <div class="item" data-idx="0" @click="toggleSend(0)">
        <div class="title-box flexcenter">
          <div class="left flexcenter">
            <div v-if="senderIndex === 0">
              <i class="iconfont iconxuanzhong"></i>
            </div>
            <div v-else>
              <i class="iconfont iconweixuanzhong"></i>
            </div>
            <div>快递配送</div>
          </div>
          <div class="right" @click="goAddrList">更改地址></div>
        </div>
      
        <div class="address-box" v-show="senderIndex === 0">
          <div class="name">
            <text>{{ consignee }}</text>
            <text>{{ phone }}</text>
          </div>
          <div>地址：{{ address }}</div>
        </div>
      </div>
    
      <div class="line"></div>
    
      <!--车销同城配送-->
      <div class="item" data-idx="1" @click="toggleSend(1)">
        <div class="title-box flexcenter">
          <div class="left flexcenter">
            <div v-if="senderIndex === 1">
              <i class="iconfont iconxuanzhong"></i>
            </div>
            <div v-else>
              <i class="iconfont iconweixuanzhong"></i>
            </div>
            <div>车销同城配送</div>
          </div>
          <div class="right" @click="openPickupBox">更改站点></div>
        </div>
      
        <div class="address-box" v-show="senderIndex ==1">
          <div class="name">
            <text>站点:{{currentPickup.pickup_name}}</text>
          </div>
          <div>地址:{{currentPickup.address}}</div>
        </div>
      </div>
    </div>
    <van-popup
        v-model="showPickup"
        closeable
    >
      <div class="list">
        <div class="title flexcenter">
          <div class="f32b">配送站点</div>
        </div>
      
        <div
            v-for="(addr, index) in pickup"
            :key="addr.id"
        >
          <div class="item" @click="selectPickup(index)" >
            <div class="line1">
              <text class="name">{{addr.pickup_name}}</text>
            </div>
            <div class="line2 flexcenter">
              <div class="addr">{{addr.address}}</div>
            </div>
          </div>
        </div>
    
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'select-addr1',
    props: {
      consignee: {
        type: String
      },
      phone: {
        type: String
      },
      regionData: {
        type: Array
      },
      address: {
        type: String
      },
      skuids:{
        type:Array
      },
      family:{
        type:String
      },
      pickup:{
        type:Array,
        default: [],
      },
      currentPickup:{
        type:Object
      }
    },
    data() {
      return {
        senderIndex: 0,
        showPickup:false,
      }
    },
    methods: {
      //切换发货方式
      toggleSend(index){
        let idx = index
        this.senderIndex = +idx

        this.$emit("selShipType",{index:idx});
      },
      goAddrList:function(){
		  this.$router.push({
		    name: 'myaddress',
		    query: {
		      mode:'checkout',
		      skuids: JSON.stringify(this.skuids),
			  family:this.family
		    }
		  })
      },
      //选择配送最好咱点
      selectPickup:function(index){
        let idx = index

        //this.currentPickup = this.pickup[idx]
        this.$emit("selectPickup",{index:idx});
        this.closePickupBox();
      },
      //关闭配送站点
      openPickupBox:function(){
        this.showPickup=true
      },
      //关闭配送站点
      closePickupBox:function(){
        this.showPickup=false
      }
    }
  }
</script>

<style scoped>
  .send-box {
    margin: 24px;
    padding: 30px 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .send-box .line {
    margin: 30px 0;
    height: 2px;
    background-color: #EEEEEE;
  }
  .send-box .item {
  
  }
  .send-box .item .title-box {
    justify-content: space-between;
    margin-bottom: 30px;
    color: #191919;
  }
  .send-box .item .title-box .left {
    font-size: 28px;
    font-weight: bold;
  }
  .send-box .item .title-box .left .iconfont {
    margin-right: 24px;
    width: 40px;
  }
  .send-box .item .title-box .right {
    font-size: 24px;
  }
  .send-box .item .address-box {
    margin-left: 64px;
    color: #777777;
    font-size: 24px;
  }
  .send-box .item .address-box .name {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: bold;
  }
  .send-box .item .address-box .spot-box .spot {
    margin-right: 24px;
    width: 112px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-color: #F5F5F5;
    border: 2px solid #C7C7C7;
    border-radius: 8px;
    font-size: 24px;
    color: #777777;
  }
  .send-box .item .address-box .spot-box .active {
    background-color: #FF9D10;
    color: #FFFFFF;
  }
  .iconweixuanzhong {
    color: #C7C7C7;
    font-size: 40px;
  }
  .iconxuanzhong {
    color: #FEA30F;
    font-size: 40px;
  }
  
  
  .list {
    margin: 24px;
    padding: 0 24px;
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  .list .title {
    justify-content: space-between;
    height: 88px;
    color: #FEA30F;
  }
  .list .title .add {
    font-size: 28px;
    color: #FEA30F;
  }
  
  .item .line1 {
    padding: 30px 0 16px;
    font-size: 28px;
    border-top: 1px solid #EEEEEE;
  }
  .item .line1 .name {
    margin-right: 10px;
  }
  .item .line2 {
    justify-content: space-between;
    padding-bottom: 28px;
  }
  .item .line2 .addr {
    width: 532px;
    font-size: 24px;
    color: #777777;
  }
</style>
