<template>
  <div class="page">
    <select-addr1
        :skuids="skuids"
        :consignee="consignee"
        :phone="phone"
        :address="address"
        :regionData="regionData"
        :family="family"
        :pickup="pickup"
        :currentPickup="currentPickup"
        @selectPickup="selectPickup"
        @selShipType="selShipType"
    ></select-addr1>
    
<!--    <select-addr2
		v-else
        v-if="user_level ==3 || user_level==4"
        :skuids="skuids"
        :consignee="consignee"
        :phone="phone"
        :address="address"
        :regionData="regionData"
        :family="family"
    ></select-addr2> -->
    
    <!--商品情况-->
    <div class="goods-list-box" v-if="family!=1">
      <div class="detail-box"
           v-for="(item, idx) in goods"
           :key="item.goods_id"
      >
        <div class="left">
          <img class="full-img" mode="widthFix" :src="item.goods_image">
          <div class="sku-box">{{item.sku_goods_name}}</div>
        </div>
        <div class="right">
      
          <div class="title-box">
            <div class="title ellipsis2">{{item.goods_name}}</div>
          </div>
      
          <div class="piao-box flexcenter">
            <div class="total">￥{{item.order_price}}</div>
            <div class="addjian flexcenter">
              <span class="count"><span class="tit">数量</span> x {{item.goods_number}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!--家庭模式-->
    <div class="family-box" v-else>
      <div
           class="family-item"
           v-for="(item, idx) in familyInfo"
           :key="item.goods_id"
      >
        <div class="title flexcenter">
          <div class="left">{{item.group_name}}</div>
          <i class="iconfont iconxiangxia"></i>
        </div>
        <template v-if="item.cart_goods">
          <div class="detail-box"
               v-for="(cart) in item.cart_goods"
               :key="cart.goods_id"
          >
            <div class="left">
              <img class="full-img" mode="widthFix" :src="cart.goods_image">
              <div class="sku-box">
                {{cart.sku_goods_name}}
              </div>
            </div>
            <div class="right">
            
              <div class="title-box">
                <div class="title ellipsis2">{{cart.goods_name}}</div>
              </div>
            
              <div class="piao-box flexcenter">
                <div class="total">￥{{cart.order_price}}</div>
                <div class="addjian flexcenter">
                  <span class="count"><span class="tit">数量</span> x {{cart.goods_number}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="zanwu">暂无商品</div>
        <div class="heji-box flexcenter">
          <div class="left">已选{{item.total_number}}件，合计：<span class="red">￥{{item.total_amount}}</span></div>
        </div>
      </div>
    </div>
    
  
    <!--商品情况-->
    <div class="paymethod-box">
      <div class="item" @click="goSelPayment">
        <div>支付方式</div>
        <div class="payIconBox">
          <div class="iconImg"><img :src="currentPayment.icon" class="payment-icon"></div>
          <div class="iconTxt">{{currentPayment.name}}</div>
          <i class="iconfont iconyou"></i>
        </div>
      </div>
    
      <div class="line"></div>
    
      <div class="item">
        <div>商品金额</div>
        <div class="red">￥{{goodsAmount}}</div>
      </div>
    
      <div class="item">
        <div>运费</div>
        <div class="red">￥0.00</div>
      </div>
    
      <div class="item">
        <div>订单金额</div>
        <div class="red">￥{{orderAmount}}</div>
      </div>
    
      <div class="line"></div>
      
      <div class="line"></div>
    
      <div class="item">
        <div>买家留言</div>
        <div class="gray">(选填)</div>
      </div>
    
      <textarea class="liuyan" placeholder="对本次交易的备注说明" />
    </div>
  
    <div class="bottom flexcenter">
      <div></div>
      <div class="cashier flexcenter">
        <div class="heji">合计: </div>
        <div class="price">￥{{orderAmount}}</div>
        <div class="buy" @click="onSubmitOrder">立即支付</div>
      </div>
    </div>
	
	<van-popup round v-model="showSelPayment" bind:close="closeSelPayment">
	  <div class="selPaymentListBox">
	    <div class="paymentItem" @click="onSelPayment(index)" v-for="(item, index) in paymentList">
	      <div v-if="currentPayment.id==item.id" class="checkbox iconfont iconxuanzhong"></div>
	      <div v-else class="checkbox iconfont iconweixuanzhong"></div>
	      <div class="payment">
	        <div class="icon"><img :src="item.icon"></img></div>
	        <div class="name">{{item.name}}</div>
	      </div>
	    </div>
	  </div>
	</van-popup>
	
  </div>
</template>

<script>
  import selectAddr1 from 'components/select-addr1'
  import selectAddr2 from 'components/select-addr2'
  
  export default {
    name: 'spjs',
    data() {
      return {
        senderIndex: 0,
        quick:0, //是否直接购买
        cartInfo:[], //直接购买商品信息
        skuids:[], //要结算的商品SKUID
        shipTye:3, //快递方式  3 快递  2 自取  1 店内
        consignee:'', //收件人
        phone:'',//收件人电话
        regionData:[],//地址区域
        address:'', //配送地址
        pickup:[],//自取地址
        currentPickup:{}, //选中的自取地址
        goods:[], //购物车商品信息
        goodsAmount:0,// 商品金额
        orderAmount:0,// 订单金额
        shipFee:0, //运费
        discount:0, //优惠金额
        currentCoupon:[], //选择要使用的优惠券
        couponList:[], //可用优惠券
        currentPayment:[], //支付方式
        paymentList:[], //可用支付方式
        userOther:'', //用户配置
        checkedGoodsAmount:0,//合计
        showRegionPicker:false,
        shipTypePos:'ship', //自动解析的地址
        showSelPayment:false, //显示支付方式选择弹框
        family:'', //判断是不是家庭单
        familyInfo:[], //家庭模式下购物车内容
        user_level:'' //判断是不是导游或业务员
      }
    },
    components: {
      selectAddr1,
      selectAddr2,
    },
    created() {
      let user_level = localStorage.getItem('user_level');
      let {family, quick, cart, skuids, address} = this.$route.query

      this.user_level = user_level

      if(typeof family != 'undefined' && family=='1')
      {
        this.family = family
      }

      if(typeof quick != 'undefined' && quick=='1')
      {
        quick = JSON.parse(quick);
        cart = JSON.parse(cart);

        this.quick = quick
        this.cartInfo = cart
      }

      if(typeof skuids != 'undefined') {
        skuids = JSON.parse(skuids);
        if(skuids.length==0&&this.family=='') {
          this.$toast('请先选择要结算的商品')

          this.$router.push({
            name: index
          })
        }

        this.skuids = skuids
      }

      if(typeof address != 'undefined') {
        address = JSON.parse(address);
        this.consignee = address.real_name
        this.phone = address.phone
        this.address = address.city+address.province+address.district+address.detail
        this.regionData = [address.province,address.city,address.district]//地址区域
        this.shipTypePos = 'ship'
      }

      this.refeshPage();

      if(this.family=='1') {
        this.getFamilyCart();
      }
    },
    methods: {
      //刷新页面数据
      refeshPage() {
        let that = this;
        let {skuids, quick, cartInfo} = this;
        this.post(
          this.API.cartCheckout,
          {skuids,quick,cart:cartInfo})
          .then(response => {
            let goods = response.data.goods;
            let paymentList = response.data.payments;
            let couponList = response.data.coupons;
            let goodsAmount = 0.00;
            let defaultAddress = response.data.defaultAddress;
            let pickup = response.data.pickup;
            goods.forEach(function(g,i){
              goodsAmount = goodsAmount + ((g.order_price*100) * g.goods_number)/100;
            })
  
            if(defaultAddress!=''&&that.consignee=='') {
              this.consignee = defaultAddress.real_name
              this.phone = defaultAddress.phone
              this.address = defaultAddress.city+defaultAddress.province+defaultAddress.district+defaultAddress.detail
              this.regionData = [defaultAddress.province,defaultAddress.city,defaultAddress.district]//地址区域
              this.shipTypePos = 'ship'
            } else if(that.consignee==''&&defaultAddress==''){
              that.shipTypePos = 'auto'
            }
  
            this.goods = goods
            this.paymentList = paymentList
            this.currentPayment = paymentList[0]
            this.pickup = pickup
            this.currentPickup = pickup[0]
  
            if(that.family!='1'){
              that.goodsAmount = goodsAmount
              that.calculateAmount();
            }
          })
      },
      /**
       * 获取家庭购物车数据
       */
      getFamilyCart:function(){
        let that = this;
        let allAmount = 0;
        this.post(
          this.API.getFamilyCart,{}
        ).then(response => {
          if(response.data) {
            response.data.forEach(function(goods,k){
              allAmount = allAmount + goods.total_amount;
            })

            this.familyInfo = response.data
            this.goodsAmount = allAmount
            this.orderAmount = allAmount
            that.calculateAmount();
          }
        })
      },
      //计算费用
      calculateAmount() {
        let goodsAmount = this.goodsAmount;
        let shipFee = this.shipFee;
        let discount = this.discount;

        this.orderAmount = goodsAmount + shipFee
        this.checkedGoodsAmount = (goodsAmount + shipFee - discount)*100
      },
      /**选择支付方式 */
      goSelPayment:function(){
        console.log("sel_payment");
        this.showSelPayment=true
      },
      closeSelPayment:function(){
        this.showSelPayment=false
      },
      onSelPayment:function(index){
        let idx = index;
        let that = this;
        console.log(idx)
        this.currentPayment=that.paymentList[idx]
        this.showSelPayment=false
      },
      /**提交订单 */
      onSubmitOrder:function(){

        let orderInfo = {};
        orderInfo.skuids = this.skuids;
        orderInfo.consignee = this.consignee;
        orderInfo.phone = this.phone;
        orderInfo.regions = this.regionData;
        orderInfo.address = this.address;
        orderInfo.currentCoupon = this.currentCoupon;
        orderInfo.payment = this.currentPayment.id;
        orderInfo.user_other = this.userOther;
        orderInfo.family = this.family;
        orderInfo.familyInfo = this.familyInfo;
        orderInfo.ship_type = this.shipTye;
        orderInfo.pickup = this.currentPickup;

        let that = this;
		
		this.post(
		  this.API.cartCreatOrder,
		  {
		    orderInfo:orderInfo,
		    quick:that.quick,
		    cart:that.cartInfo
		  }
		).then(response => {
		  if(response.data.err=='1'){
		    that.$toast(response.data.msg)
		    return false;
		  }else{
		    let trans_no = response.data.paydata.data.trans_no;
		    if(response.data.paydata.data.type=='local')
		    {
				this.$router.push({
				  name: 'myorders',
				  query: {
				    ostatus:"2",
				  }
				})
		        return ;
		    }
			else
			{
				console.log(response.data.paydata.data.result.tn);
				// TODO: 需要请求后端接口获取订单号
				upsdk.pay({
				  tn: response.data.paydata.data.result.tn,
				  success: function(res){
					console.log(res);
				    that.checkPay(trans_no)
				  },
				  fail: function(err){
				    // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
					console.log(err);
					that.cancelPay(err)
				  }
				});
			}
		  }
		})
        
        console.log("提交订单");
      },
      checkPay:function(trans_no,res) {
        this.post(this.API.checkPay,{ trans_no:trans_no })
			.then(response => {
				  if(response.data.err=='0')
				  {
					that.$toast("支付成功")
				  }
				  else
				  {
					that.$toast(response.data.msg);
				  }
				  
				  this.$router.replace({
					name: 'myorders',
					query: {
					  ostatus: 2
					}
				  })
			})
      },
      cancelPay:function(res){
        console.log(res);
        this.$toast("您取消了支付"+res.msg);
		this.$router.replace({
		  name: 'myorders',
		  query: {
		    type: 1
		  }
		})
      },
      /**
       * 选择配送方式
       */
      selShipType:function(e){
        let idx = e.index;
        console.log(e);
        let type = 3;
        if(idx=='0'){ type=3; }
        if(idx=='1'){ type=2; }
        if(idx=='2'){ type=1; }
        this.shipTye = type
      },
      /**
       * 选择配送站点
       */
      selectPickup:function(e){
        let idx = e.index;
        this.currentPickup = this.pickup[idx]
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
    min-height: 100%;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }

  .send-box {
    margin: 24px;
    padding: 30px 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .send-box .line {
    margin: 30px 0;
    height: 2px;
    background-color: #EEEEEE;
  }
  .send-box .item {
  
  }
  .send-box .item .title-box {
    justify-content: space-between;
    margin-bottom: 30px;
    color: #191919;
  }
  .send-box .item .title-box .left {
    font-size: 28px;
    font-weight: bold;
  }
  .send-box .item .title-box .left .iconfont {
    margin-right: 24px;
    width: 40px;
  }
  .send-box .item .title-box .right {
    font-size: 24px;
  }
  .send-box .item .address-box {
    margin-left: 64px;
    color: #777777;
    font-size: 24px;
  }
  .send-box .item .address-box .name {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: bold;
  }
  .send-box .item .address-box .spot-box .spot {
    margin-right: 24px;
    width: 112px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-color: #F5F5F5;
    border: 2px solid #C7C7C7;
    border-radius: 8px;
    font-size: 24px;
    color: #777777;
  }
  .send-box .item .address-box .spot-box .active {
    background-color: #FF9D10;
    color: #FFFFFF;
  }

  .goods-list-box {
    background-color:#FFFFFF;
    margin:24px;
  }
  .detail-box {
    display: flex;
    justify-content: space-between;
    margin: 24px;
    padding: 20px 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .detail-box .left {
    margin-right: 24px;
    width: 160px;
  }
  .detail-box .left .sku-box {
    display: block;
    padding:2px 10px;
    font-size:26px;
    color:#FCC601;
    border-radius: 5px;
    float: right;
    margin-top: -60px;
    margin-right: 20px;
    background-color:#191919;
  }
  .detail-box .right {
    flex: 1;
  }
  .detail-box .right .title-box {
    margin-bottom: 54px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }

  .detail-box .right .piao-box {
    justify-content: space-between;
    font-size: 28px;
  }
  .detail-box .right .piao-box .total {
    font-size: 30px;
    color: #F84F41;
  }
  .detail-box .right .piao-box .addjian .count {
    width: 160px;
    text-align: center;
  }
  .detail-box .right .piao-box .addjian .count .tit {
    font-size:24px;
    color:#cccccc;
  }

  .paymethod-box {
    margin: 24px 24px 160px 24px;
    padding: 20px 24px;
    padding-bottom: 100px;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-size: 28px;
    color: #191919;
  }
  .paymethod-box .line {
    height: 2px;
    background-color: #EEEEEE;
  }
  .paymethod-box .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .paymethod-box .red {
    font-weight: bold;
    color: #F84F41;
  }
  .paymethod-box .gray {
    color: #777777;
  }
  .paymethod-box .liuyan {
    width: 100%;
    height: 72px;
    border: 2px solid #F5F5F5;
  }
  .payment-icon {
    width: 40px;
    height: 40px;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: constant(safe-area-inset-bottom);//兼容 IOS<11.2
    bottom: env(safe-area-inset-bottom);//兼容 IOS>11.2
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .buy {
    margin-right: 30px;
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

  .paymethod-box .paymentBox {
    height: 80px;
  }
  .payIconBox {
    display: flex;
    line-height: 62px;
    vertical-align: middle;
  }
  .payIconBox .iconImg {
    margin-right: 6px;
    padding-top: 10px;
  }
  .payIconBox .iconTxt {
    padding-top: 2px;
  }

  .family-box {
    padding-bottom: 0px;
  }
  .family-box .family-item {
    margin: 30px 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
  }
  .family-box .family-item .zanwu {
    height: 168px;
    line-height: 168px;
    font-size: 28px;
    color: #777777;
    text-align: center;
  }
  .family-box .family-item .title {
    justify-content: space-between;
    height: 88px;
    box-sizing: border-box;
    padding: 0 24px;
    border-bottom: 1px solid #EEEEEE;
    font-size: 28px;
    font-weight: bold;
  }
  .family-box .family-item .heji-box {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 24px;
    height: 100px;
    font-size: 28px;
    border-top: 1px solid #EEEEEE;
  }
  .family-box .family-item .heji-box .left {
    color: #777777;
  }
  .family-box .family-item .heji-box .left .red {
    font-weight: bold;
    color: #FF3B33;
  }
  .family-box .family-item .heji-box .right {
    width: 128px;
    height: 72px;
    line-height: 72px;
    border: 1px solid #FCC601;
    border-radius: 36px;
    text-align: center;
    color: #FCC601;
    font-size: 32px;
    font-weight: bold;
  }

</style>
