<template>
  <div class="pp">
    <div class="noAddress">
      <van-cell title="从地址簿选择" @click="goAddrList" is-link icon="location-o"/>
      <van-cell-group>
        <van-field
            v-model="consignee"
            label="名称:"
            placeholder="收货人姓名"
            label-width="90px"
        />
        <!-- <div @click="goAutoParse" class="autoParse">智能解析</div> -->
        <van-field
            v-model="phone"
            data-name="phone"
            bindinput="bindInputData"
            label="电话:"
            placeholder="收货人手机号"
            label-width="90px"
        />
        <van-cell is-link custom-class="selectRegion">
          <div slot="title">
            <div class="selectRegtionbox">
              <div class="regionTxt">
                <van-field
                    clearable
                    v-model="area"
                    @click="isShowArea=true"
                    label="选择区域"
                    label-width="7em"
                    placeholder="点击选择所在区域"
                />
              </div>
            </div>
          </div>
        </van-cell>
        <van-field
            v-model="address"
            data-name="address"
            bindinput="bindInputData"
            label="地址:"
            placeholder="如道路,门牌号,小区,楼栋号,单元室等"
            title-width="90px"
        />
      </van-cell-group>
      <!-- <div class="icon"><van-icon name="location-o" /></div>
      <div class="text">点击添加收货地址</div> -->
      <div class="addressBoxLine"></div>
    </div>
    <van-popup
        v-model="showAutoParse"
        closeable
        >
      <div class="auto_addr">
        <div class="auto_str">
          <textarea round bindblur="autoParseAddress" placeholder="请粘贴您要解析的内容"/>
        </div>
      </div>
    </van-popup>
	<van-popup v-model="isShowArea" position="bottom" :style="{ height: '40%' }">
		<van-area  @confirm="chooseArea" title="选择区域" :area-list="areaList" value="" />
	</van-popup>
  </div>
</template>

<script>
  import { areaList } from '@vant/area-data';
  
  export default {
    name: 'select-addr2',
    props: {
      consignee: {
        type: String
      },
      phone: {
        type: String
      },
      regionData: {
        type: Array
      },
      address: {
        type: String
      },
      skuids:{
        type:Array
      },
      family:{
        type:String
      }
    },
    data() {
      return {
        area: '',
        areaList,
        isShowArea: false,
        showAutoParse:false,
      }
    },
    methods: {
      chooseArea(e) {
        console.log('area', e)
        let [{name: name1}, {name: name2}, {name: name3}] = e
        this.isShowArea = false
        this.area = `${name1}${name2}${name3}`
      },
      goAddrList:function(){
        let {family, skuids} = this
        
        this.$router.replace({
          name: 'myaddress',
          query: {
            mode: 'checkout',
            family,
            skuids: JSON.stringify(skuids),
          }
        })
      },
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail;
        let name = dataset.name;

        _this[name] = value;
        _this.name = _this[name]

      },
      //显示选择区域选择器
      toSelectRegion() {
        this.setData({
          showRegionPicker:true
        });
      },
      //取消选择
      hideSelectRegion() {
        this.setData({
          showRegionPicker:false
        });
      },
      //改变选值
      bindRegionChange: function (e) {
        this.setData({
          regionData: e.detail.value
        })
      },
      //完成选择
      comfirmSelectRegion()
      {
        this.setData({
          showRegionPicker:false
        });
      },
      goAutoParse:function(){
        this.setData({
          showAutoParse:true
        })
      },
      closeAutoParse() {
        this.setData({ showAutoParse: false });
      },
      //自动解析地址
      autoParseAddress:function(e){
        let addressStr = e.detail.value;
        let addrInfo = window.AddressParse && AddressParse.parse(addressStr);

        this.setData({
          consignee:addrInfo[0].name, //收件人
          phone:addrInfo[0].mobile,//收件人电话
          regionData:[addrInfo[0].province,addrInfo[0].city,addrInfo[0].area],//地址区域
          address:addrInfo[0].details, //配送地址
          shipTypePos:'ship',
          showAutoParse: false
        });

        console.log(addrInfo);
      }
    }
  }
</script>

<style scoped>
  .noAddress {
    background-color: #FFF;
    font-size:28px;
    border-radius: 16px;
    margin: 24px 24px 24px 24px;
    overflow: hidden;
  }
  .noAddress .icon {
    font-size:48px;
  }
  .selectRegion {
  
  }
  
  .selectRegtionbox {
    display: flex;
  }
  .regionTit {
    flex: 0.8;
  }
  .regionTxt {
    flex:3.2;
  }
  .regionTxt .placeholder {
    color:#cecece;
  }
  .addressBoxLine {
    height:6px;
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu0AAAAGCAYAAAB3oqAPAAAAAXNSR0IArs4c6QAABLFJREFUeAHtnG1olmUUx397b2vOtZUTJ7nFZuSk5iozSJPslUphUhjZKyPL8IPBsvcPFb04yA+SWYxeyEiKgtQgC6OSsKw1C2fkpM1y5sytOeeme+1cz7UPz6bQfR2CR905MHherv91Pfd9n3Ou87/P/17SkBijrasbVtXC9ztGfxPtfeFEeOJhmFIYbfyoUfV/w4t10HF81BcR3y64AJZMh9SkiID4Yf0DULseNm2J/zT66/E5UP0AlE+Ljokb2XwEnt0O+7riPgx4eaWc+uoKyE4LAMUP/fgzeOcjGJDzEGoZ6fDQYrj2qlBkbHz7MXj+R9jZpoIz9Rx4+nIoyNTh2foDrH4bunvCJ0hOhkW3wh3zISnc8Xr6JeQk3L5qCV/aISZm+WMvzdXh2dUIK9fCoX90E7hrvvQuSA93vEHJQG/9Ch/sgZNko//8PePE7apnwCzxfZXtb4UXXoXmfSo45WXwqMR8zjgVfkMTvL4T+gbD4Wnidi7XzS8Ox8YQnZJwat6A+gbdBEWTfa6fVKDCfyenvuYnONIbDndhdlsJ3H8RJIeHHPT2wWvr4Iut4Ys7RL4knBUPwrRSFb6xA56TlHNAtluNzZXtdXk5ZKYq0C7Q1m+E9zfAoMLxsiTJLrsXZkvCVVirpFh37LuV6WZ6Pjx5mVyCsxSLO8iWb2HNu3Bc4XgpKXB3JSy8SbV4l7hdTT1s+0sFZ3I2PDMTinTpBnbs8jF/uFP3A26ZB1WLpMCS8xBo/eJ2Ltd98nsgcHh4bgY8finMOE+HZ69ssC7XtxzQTXCFBNzyKimwZMNV2Ieyx70pe92AIuQy5HQvu1hO+wnr7mmWinkNtB464atIH8wRb3LBnBkeTXI9ee83WCd/biMPtSypF1wSu3pSKHJ4/EGpFt2xNzbpJiibKpu3JPH8XBX+8z+lZvxZ8oiiXk6Rzfs+2bxuL1EtDUbUjKgZUQsLHiNqiSVqcnNilo4rgBE1I2ph0e5HG1FLKFF7SohaXnhp6a9dooma3JzYpuQK8URtZNH+6Zf+LnNff7g7p8lUjn3dfE04VhCHhfC+VAd1B1VwinM8+yw8W4dnu1TLr9RK8Xo0fAJ3y6fyRrhnodzykeo50HqFda3+BTbvDQQOD8+Xmx7OmcvydHiMqCWOqP3hr70RtXDftY6addTCvUYQ1lE7fTtqRtRULo911M6Yjpov2nuOeVnAN6LL0FjBudKzWAolRRo0De1eFtEmLTON3TDFtw3Sw+tl3xp0chAnC9H05rOFJTxSBTMv0fx0WoQjODlMU6cKTsUE3y4aLxIBlRlRM6KmcZyx3lFzsog8ZUfNiJpJHxUxZ9JHkz4q3IaY9PHltdCm1CJdN9vLXpXSRycHcbIQTXkVkz4aURtx2VPPBI3P9eePOKbob9o7wDlzw+7omPiRpcWerEwQgZ3Cvt7vdczdonELNafhvPNCWCx/8jLcjKgZUbOOWljcWEdtbEsfE0jUnG7f6fdVZtJHkz6a9DEsdE5h6WPSUOWSIfXDGE4O4mQhCos9jPE/aXwUy5/2D2M8Jg9jVIzhhzGMqCm83oiaETUjamGBY0TNiFqCOmpG1OyfiZwsWf0Lz5t61CZi/VoAAAAASUVORK5CYII=);
    background-size: 100% 6px;
  }
  
  .autoParse {
    font-size:22px;
    float: right;
    color:#489ef7;
    border:2px solid #489ef7;
    padding:0px 6px;
    border-radius: 4px;
    margin-top: -60px;
    margin-right: 20px;
    z-index: 99;
    position: relative;
  }
  .auto_addr .auto_str {
    padding:20px;
    min-height: 300px;
  }
  .auto_addr .auto_str textarea {
    height: 300px;
    background: #efefef;
    padding:20px;
    font-size:46px;
    border-radius: 14px;
  }
</style>
